import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes }  from 'styled-components';

import StarsOne from '../../images/stars/1.png';
import StarsTwo from '../../images/stars/2.png';
import StarsThree from '../../images/stars/3.png';
import StarsFour from '../../images/stars/4.png';
import StarsFive from '../../images/stars/5.png';
import StarsSix from '../../images/stars/6.png';

const scaleInOne = keyframes`
  0% { transform: scale(1, 1) translateX(0px); }
  100% { transform: scale(0.8, 0.7) translateX(20px); }
`

const scaleInTwo = keyframes`
  0% { transform: scale(1, 1) translateX(0px); }
  100% { transform: scale(0.9, 0.8) translateX(-20px); }
`

const fadeIn = keyframes`
  0% {
    opacity:0
  }
  100% {
    opacity:1
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh + 5% + 453px);
  overflow: hidden;
  margin: 0px;
  padding: 0px;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  transition-delay: 0.1s;
  background: white;
  animation: ${fadeIn} 2s forwards ease-in-out;

  @media (max-width: 600px) {
    height: calc(100vh + 5% + 453px + 400px);
  }

  // @media (max-width: 1100px) {
  //   opacity: 0;
  //   transition: opacity 0.3s ease-in-out;
  // 	transition-delay: 0.1s;
  // }
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 10%;
`;

const Img = styled.img`
	object-fit: contain;
	animation: ${({ animation = scaleInOne }) => animation} ${({ duration = 3 }) => duration}s infinite alternate;
	animation-delay: ${({ delay = 5 }) => delay}s;
`;

const StarsSection = ({ }) => {
  return (
    <Container>
    	<Column>
    		<Img delay={1} animation={scaleInTwo} src={StarsOne}/>
    		<Img delay={2} animation={scaleInOne} src={StarsTwo} />
    		<Img delay={1} animation={scaleInTwo} src={StarsThree} />
    		<Img delay={3} animation={scaleInOne} src={StarsFour} />
    		<Img delay={1} animation={scaleInTwo} src={StarsFive} />
    		<Img delay={2} animation={scaleInOne} src={StarsSix} />
    	</Column>

    	<Column>
    		<Img delay={2} animation={scaleInOne} src={StarsSix} />
    		<Img delay={1} animation={scaleInOne} src={StarsFive} />
    		<Img delay={2} animation={scaleInOne} src={StarsFour} />
    		<Img delay={3} animation={scaleInOne} src={StarsThree} />
    		<Img delay={1} animation={scaleInOne} src={StarsTwo} />
    		<Img delay={3} animation={scaleInOne} src={StarsOne}/>
    	</Column>
    </Container>
  );
};

StarsSection.propTypes = {
};

export default StarsSection;
