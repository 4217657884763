import React from 'react';
import PropTypes from 'prop-types';
import styled  from 'styled-components';

const Container = styled.div`
  display: flex;
  margin: 40px 0px;
`;

const Emoji = styled.div`
	display: flex;
	padding: 8px;
	margin: 0px 8px 0px 0px;
	font-size: 24px;
`;

const Content = styled.p`
	padding: 8px;
	margin: 0px;
	font-size: 18px;
	line-height: 26px;
	font-family: Nanumgothiccoding, sans-serif;
	color: #0400BF;
	text-align: left;
`;

const EmojiSection = ({ emoji = ``, children }) => {
  return (
    <Container>
    	<Emoji>{emoji}</Emoji>
      <Content>{children}</Content>
    </Container>
  );
};

EmojiSection.propTypes = {
  emoji: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default EmojiSection;
