import React from 'react';
import './App.css';
import styled, { keyframes } from 'styled-components';

import StarsSection from './components/sections/starsSection';
import EmojiSection from './components/sections/emojiSection';

import SlurpeeGIF from './images/slurpee.gif';

const LOOM = 'https://loom.com/share/folder/e46e98f017a24cc7bb25b7e2bffca46a';
const TECH_CRUNCH = 'https://techcrunch.com/2019/02/28/cherry-lets-startup-employees-choose-their-own-office-perks/';
const YC = 'https://blog.ycombinator.com/9-companies-from-the-yc-w19-batch-part-3/';
const FORBES = 'https://www.forbes.com/sites/frederickdaso/2020/02/03/cherry-a-y-combinator-office-perks-startup-provides-flexible-employee-benefits/#44b0a6a73143';
const MEDIUM = 'https://medium.com/@gillian_obrien/cherry-is-shutting-down-our-story-4f4a67a9b1e9';
const LINKEDIN = 'https://www.linkedin.com/in/emilyblaineobrien/';

const popUpwards = keyframes`
  0% {
    -webkit-transform:matrix(.97,0,0,1,0,12);
    transform:matrix(.97,0,0,1,0,12);
    opacity:0
  }
  20% {
    -webkit-transform:matrix(.99,0,0,1,0,2);
    transform:matrix(.99,0,0,1,0,2);
    opacity:.7
  }
  40% {
    -webkit-transform:matrix(1,0,0,1,0,-1);
    transform:matrix(1,0,0,1,0,-1);
    opacity:1
  }
  70% {
    -webkit-transform:matrix(1,0,0,1,0,0);
    transform:matrix(1,0,0,1,0,0);
    opacity:1
  }
  100% {
    -webkit-transform:matrix(1,0,0,1,0,0);
    transform:matrix(1,0,0,1,0,0);
    opacity:1
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 700px;
  animation: ${popUpwards} 2s forwards ease-in-out;
  animation-delay: 1s;
  opacity: 0;

  @media (max-width: 600px) {
    width: 80%;
  }
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  pointer-events: none;
`;

const PopOutPortrait = styled.div`
  display: flex;
  max-width: 100vw;
`;

const Portrait = styled.img`
  display: flex;
  object-fit: contain;
  maxWidth: 100%;
  animation: ${popUpwards} 2s forwards ease-in-out;
  animation-delay: 2s;
  opacity: 0;

  @media (max-width: 800px) {
    max-height: 200px;
  }
`;

const Link = styled.a`
  color: #0400BF;
  border-bottom: 2px solid;
  text-decoration: none;
`;

function App() {
  return (
      <Container>
      <StarsSection />
        <ContentContainer>
          <EmojiSection emoji={`👋🏼`}>
            Hi, I'm Emily (not Emmmily).
          </EmojiSection>
          <EmojiSection emoji={`🍒`}>
            I was most recently a Co-Founder of Cherry where we built a corporate card for personalized employee benefits.
            <br/>
            <br/>
            <strong>Problem:</strong> Check out the issue we helped <Link href={`https://docsend.com/view/hdb5n8eyipu2exw8`} target="_probs">solve</Link>.
            <br />
            <br/>
            <strong>Product:</strong> While you're at it you might want to watch some Loom <Link href={LOOM} target="_demos">demos</Link>.
            <br />
            <br/>
            <strong>Press:</strong> You can read more about Cherry <Link target="_tc" href={TECH_CRUNCH}>here</Link>, <Link target="_yc" href={YC}>here</Link>, <Link target="_forbes" href={FORBES}>here</Link> and <Link target="_medium" href={MEDIUM}>here</Link>.
          </EmojiSection>
          <EmojiSection emoji={`📱`}>
            In past lives I've built fun iOS apps like ThisAM (daily news app) & Vote Your Values (recording booth app) for Refinery29 & an IRL sticker scavenger hunt app at GIPHY.
          </EmojiSection>
          <EmojiSection emoji={`🎨`}>
            Before becoming an engineer I designed album art for musicians including Yoko Ono & Chimera Music.
          </EmojiSection>
          <EmojiSection emoji={`🚀`}>
            I'm looking for a Product Management role at an exciting, fast-paced startup. If you want to let me know about an opening please DM me on <Link target="_linkedin" href={LINKEDIN}>LinkedIn</Link>. UPDATE: Found my next thing!
          </EmojiSection>
          <EmojiSection emoji={`✌🏼`}>
            Cya.
          </EmojiSection>
        </ContentContainer>
        <Footer>
          <PopOutPortrait>
            <Portrait src={SlurpeeGIF} alt="Emily"/>
          </PopOutPortrait>
        </Footer>
      </Container>
  );
}

export default App;
